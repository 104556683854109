<template>
  <div class="section">
    <span class="is-size-4">User Information</span>
    <div class="is-flex is-justify-content-space-between">
      <b-field label="Filters" class="mt-2">
        <b-input
          ref="input"
          v-model="searchString"
          class="mr-2"
          placeholder="e.g. Douglas Adams"
          :loading="$apollo.loading"
          @input="updateSearch"
        />
        <Facet
          v-model="facetRoleSelection"
          class="is-align-items-center mr-2"
          :options="facetRole.options"
          :value="facetRoleSelection"
          @input="$apollo.queries.data.refetch()"
        />
        <Facet
          v-if="facetCountry"
          v-model="facetCountrySelection"
          class="is-align-items-center mr-2"
          :options="facetCountry.options"
          :value="facetCountrySelection"
          @input="$apollo.queries.data.refetch()"
        />
        <b-input
          ref="inputPostalCode"
          v-model="searchStringPostalCode"
          placeholder="e.g. 73111"
          :loading="$apollo.loading"
          @input="updateSearchPostalCode"
        />
      </b-field>
    </div>
    <p v-if="searchString" class="control">
      <b-button type="is-primary" @click="clearSearch()">
        Clear
      </b-button>
    </p>
    <b-table
      :loading="$apollo.loading"
      :data="data"
      striped
      hoverable
      :total="dataCount"
      :per-page="limit"
      :current-page="1"
      backend-sorting
      backend-pagination
      backend-filtering
      :checked-rows.sync="checkedRows"
      paginated
      checkable
      @page-change="onPageChanged"
      @sort="onSortChanged"
    >
      <template slot="empty">
        <EmptyState background="" class="is-flex-column">
          No users found.
          <b-button @click="clearSearch()">
            reset search
          </b-button>
        </EmptyState>
      </template>

      <template slot="bottom-left">
        <div class="is-flex is-flex-direction-column">
          <div class="mb-2 is-flex is-flex-direction-row">
            <div>
              <b-select v-model="limitSelection">
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </b-select>
            </div>
            <div class="mt-2 mx-1"><b>Total</b>: {{ dataCount }}</div>
            <b-button class="is-small" @click="downloadCSVData">
              CSV
            </b-button>
          </div>
          <div v-if="checkedRows.length > 0">
            <b-field label="Checked Actions">
              <Facet
                v-model="facetActionSelection"
                class="is-align-items-center mr-2 mt-1"
                :options="facetAction.options"
                :value="facetActionSelection"
                @input="onAction"
              />
            </b-field>
            <section v-if="isPromoting" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Promote all Selected Users to Organizer?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="promoteSelected" />
            </section>
            <section v-if="isDemoting" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Demote all Selected Users to Member?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="demoteSelected" />
            </section>
            <section v-if="isBanning" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Ban all Selected Members and Organizers?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="banSelected" />
            </section>
            <section v-if="isUnbanning" class="content elevation-1 boxed mb-2">
              <p class="is-size-5 has-text-weight-semibold">Un-Ban all Selected Users?</p>
              <UndoConfirmButtons :confirm-enabled="true" @undo="unAction" @confirm="unbanSelected" />
            </section>
          </div>
        </div>
      </template>

      <template slot="default">
        <b-table-column v-slot="props" field="username" label="Username" sortable>
          <div class="is-flex is-align-items-center">
            <Avatar :seed-string="props.row | user_name" :color="props.row.avatar_color" />
            <a v-show="!props.row.cancelled_by_user_at" @click="onClickUsername(props.row)">
              {{ props.row.username }}
            </a>
          </div>
        </b-table-column>
        <b-table-column v-slot="props" field="first_name" label="First Name" sortable>
          <span v-if="props.row.deleted_at">
            <s>{{ props.row.first_name }}</s>
          </span>
          <span v-else>
            {{ props.row.first_name }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props" field="last_name" label="Last Name" sortable>
          <span v-if="props.row.deleted_at">
            <s>{{ props.row.last_name }}</s>
          </span>
          <span v-else>
            {{ props.row.last_name }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props" field="email" label="Email" sortable>
          <a :href="`mailto:${props.row.email}`">{{ props.row.email }}</a>
        </b-table-column>
        <b-table-column v-slot="props" field="role" label="Role" sortable>
          {{ roleName(props.row.role) }}
        </b-table-column>
        <b-table-column v-slot="props" field="country" label="Country" sortable>
          {{ props.row.country }}
        </b-table-column>
        <b-table-column v-slot="props" field="postal_code" label="Postal" sortable>
          {{ props.row.postal_code }}
        </b-table-column>
        <b-table-column v-slot="props" field="created_at" label="Created" sortable>
          {{ props.row.created_at | formatFromNow }}
        </b-table-column>
        <b-table-column v-slot="props" field="username" label="Events" sortable>
          <a @click="onClickEvent(props.row)">
            View
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import Avatar from "@/components/common/Avatar.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import Facet from "@/components/forms/Facet.vue";
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import { USERS_LIST_FOR_ADMINS } from "@/models/users/operations.gql";
import { LIST_COUNTRIES } from "@/models/countries/operations.gql";
import { UPDATE_USER_BY_PK } from "@/models/users/operations.gql";
import { whereUserMatchesWithEmail, whereUserMatchesWithPostalCode } from "@/models/users/predicates.js";

export default {
  name: "UsersAdministration",
  components: {
    Avatar,
    EmptyState,
    UndoConfirmButtons,
    Facet
  },
  data() {
    const DEFAULT_ROLE = { label: "Any Role", role: undefined };
    const DEFAULT_COUNTRY = { label: "Any Country", country: undefined };
    const DEFAULT_ACTION = { label: "Select", action: undefined };
    return {
      DEFAULT_ROLE: DEFAULT_ROLE,
      DEFAULT_COUNTRY: DEFAULT_COUNTRY,
      DEFAULT_ACTION: DEFAULT_ACTION,
      countries: [],
      data: [],
      dataCount: 0,
      currentPage: 1,
      limit: 100,
      limitSelection: "100",
      offset: 0,
      checkedRows: [],
      searchString: "",
      searchStringPostalCode: "",
      orderBy: {
        created_at: "desc"
      },
      facetRoleSelection: DEFAULT_ROLE,
      facetRole: Object.freeze(
        (() => {
          return {
            DEFAULT_ROLE,
            options: [
              DEFAULT_ROLE,
              { label: "Members Only", role: "owner" },
              { label: "Organizers Only", role: "organizer" },
              { label: "Admins Only", role: "app_admin" }
            ]
          };
        })()
      ),
      facetCountrySelection: DEFAULT_COUNTRY,
      facetCountry: undefined,
      facetActionSelection: DEFAULT_ACTION,
      facetAction: Object.freeze(
        (() => {
          return {
            DEFAULT_ACTION,
            options: [
              DEFAULT_ACTION,
              { label: "Promote", action: "promote" },
              { label: "Demote", action: "demote" },
              { label: "Ban", action: "ban" },
              { label: "Unban", action: "unban" }
            ]
          };
        })()
      ),
      isPromoting: false,
      isDemoting: false,
      isBanning: false,
      isUnbanning: false
    };
  },
  computed: {
    apolloVars() {
      var where = {};
      if (this.facetRoleSelection.role !== undefined) {
        where = {
          ...where,
          role: { _eq: this.facetRoleSelection.role }
        };
      }
      if (this.facetCountrySelection.country !== undefined) {
        where = {
          ...where,
          country: { _eq: this.facetCountrySelection.country }
        };
      }
      if (this.searchString) {
        where = {
          ...where,
          ...whereUserMatchesWithEmail({ query: this.searchString })
        };
      }
      if (this.searchStringPostalCode) {
        where = {
          ...where,
          ...whereUserMatchesWithPostalCode({ query: this.searchStringPostalCode })
        };
      }
      return {
        limit: this.limit,
        offset: this.offset,
        where: where,
        orderBy: [this.orderBy]
      };
    }
  },
  watch: {
    limitSelection(val) {
      //this.currentPage = newPage;
      //this.offset = (newPage - 1) * this.limit;
      this.limit = parseInt(val);
    }
  },
  methods: {
    downloadCSVData() {
      let csv = `"Username","First Name","Last Name","Email","Role","Country","Postal Code","Created"\n`;
      this.data.forEach(row => {
        csv += `"${row.username}","${row.first_name}","${row.last_name}","${row.email}","${row.role}","${row.country}","${row.postal_code}","${row.created_at}"\n`;
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "users.csv";
      anchor.click();
    },
    roleName(role) {
      if (role === "app_admin") {
        return "Admin";
      } else if (role === "organizer") {
        return "Organizer";
      } else {
        return "Member";
      }
    },
    promote(user_id) {
      this.$apollo.mutate({
        mutation: UPDATE_USER_BY_PK,
        variables: {
          id: user_id,
          changes: {
            role: "organizer"
          }
        }
      });
    },
    demote(user_id) {
      this.$apollo.mutate({
        mutation: UPDATE_USER_BY_PK,
        variables: {
          id: user_id,
          changes: {
            role: "owner"
          }
        }
      });
    },
    ban(user_id) {
      this.$apollo.mutate({
        mutation: UPDATE_USER_BY_PK,
        variables: {
          id: user_id,
          changes: {
            deleted_at: "now()",
            deleted_by_id: this.$auth.user.id
          }
        }
      });
    },
    unban(user_id) {
      this.$apollo.mutate({
        mutation: UPDATE_USER_BY_PK,
        variables: {
          id: user_id,
          changes: {
            deleted_at: null,
            deleted_by_id: null
          }
        }
      });
    },
    onPageChanged(newPage) {
      this.currentPage = newPage;
      this.offset = (newPage - 1) * this.limit;
    },
    onSortChanged(field, dir) {
      if (field == "created_at") {
        this.orderBy = {
          created_at: dir
        };
      } else if (field == "username") {
        this.orderBy = {
          username: dir
        };
      } else if (field == "first_name") {
        this.orderBy = {
          first_name: dir
        };
      } else if (field == "last_name") {
        this.orderBy = {
          last_name: dir
        };
      } else if (field == "email") {
        this.orderBy = {
          email: dir
        };
      } else if (field == "role") {
        this.orderBy = {
          role: dir
        };
      } else if (field == "country") {
        this.orderBy = {
          country: dir
        };
      } else if (field == "postal_code") {
        this.orderBy = {
          postal_code: dir
        };
      }
    },
    clearSearch() {
      this.offset = 0;
      this.currentPage = 1;
      this.searchString = "";
      this.searchStringPostalCode = "";
      this.facetRoleSelection = this.DEFAULT_ROLE;
      this.facetCountrySelection = this.DEFAULT_COUNTRY;
    },
    updateSearch() {
      this.$refs.input.focus();
      this.offset = 0;
      this.currentPage = 1;
    },
    updateSearchPostalCode() {
      this.$refs.inputPostalCode.focus();
      this.offset = 0;
      this.currentPage = 1;
    },
    unAction() {
      this.isPromoting = false;
      this.isDemoting = false;
      this.isBanning = false;
      this.isUnbanning = false;
      this.facetActionSelection = this.DEFAULT_ACTION;
    },
    onAction(val) {
      this.unAction();
      if (val.action === "promote") {
        this.isPromoting = true;
      }
      if (val.action === "demote") {
        this.isDemoting = true;
      }
      if (val.action === "ban") {
        this.isBanning = true;
      }
      if (val.action === "unban") {
        this.isUnbanning = true;
      }
    },
    userSelectRefId(id) {
      return `user-select-${id}`;
    },
    unselect(id) {
      var refId = this.userSelectRefId(id);
      this.$refs[refId].$refs.input.checked = false;
    },
    promoteSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        if (u.role == "owner") {
          this.promote(u.id);
        }
      }
      this.unAction();
    },
    demoteSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        if (u.role == "organizer") {
          this.demote(u.id);
        }
      }
      this.unAction();
    },
    banSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        if (u.role != "app_admin") {
          this.ban(u.id);
        }
      }
      this.unAction();
    },
    unbanSelected() {
      for (var i = 0; i < this.checkedRows.length; i++) {
        var u = this.checkedRows[i];
        this.unban(u.id);
      }
      this.unAction();
    },
    onClickUsername(row) {
      let routeData = this.$router.resolve({
        name: "user_profile",
        params: {
          username: row.username
        }
      });
      window.open(routeData.href, "_blank");
    },
    onClickEvent(row) {
      let routeData = this.$router.resolve({
        name: "event_admin"
      });
      window.open(routeData.href + '?searchStringHost=' + row.username, "_blank");
    }
  },
  apollo: {
    data: {
      query: USERS_LIST_FOR_ADMINS,
      debounce: 500,
      variables() {
        return this.apolloVars;
      },
      update({ users, users_aggregate }) {
        this.dataCount = users_aggregate.aggregate.count;
        return users;
      }
    },
    countries: {
      query: LIST_COUNTRIES,
      update({ countries }) {
        var countryOptions = countries.reduce(
          (result, c) => {
            result.push({ label: c.name, country: c.name });
            return result;
          },
          [this.DEFAULT_COUNTRY]
        );
        this.facetCountrySelection = this.DEFAULT_COUNTRY;
        this.facetCountry = Object.freeze(
          (() => {
            return {
              options: countryOptions
            };
          })()
        );
      }
    }
  }
};
</script>
